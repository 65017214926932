import React from 'react';
import Image from 'next/image';
import { ViewWrapper, SinglePageWrapper, StyledPageBanner } from '@/styles/index';
import Navbar from '@/components/Layout/Navbar';
// import Footer from '@/components/Layout/Footer';
import BaseCTA from '@/components/Layout/CTA';
import HeadMeta from '@/components/HeadMeta';
import { useSession } from 'next-auth/client';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@/components/Layout/Footer'));

const PageNotFound = () => {
  const [session] = useSession();
  return (
    <ViewWrapper>
      <HeadMeta
        title="404 – Not found, Pollme: Free online Poll and Micro-survey tool"
        robots="noindex, nofollow"
      />
      <Navbar session={session} />
      <SinglePageWrapper>
        <StyledPageBanner>
          <div className="content-col">
            <h1>Page not found!</h1>
            <div className="lead">It seems you reached a URL that does not exist.</div>
          </div>
          <div className="hero-col">
            <div className="img-wrapper">
              <Image
                src="/static/images/drawings/alien_back.png"
                alt="pollme 404 not found"
                layout="fill"
              />
            </div>
          </div>
        </StyledPageBanner>
      </SinglePageWrapper>
      <BaseCTA />
      <Footer />
    </ViewWrapper>
  );
};
export default PageNotFound;
